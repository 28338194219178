import React, { createContext, useState, useEffect } from 'react';
import { createGlobalStyle, css } from 'styled-components';
import api from '../api';
import history from '../history';
import { ConfigProvider, Button } from "antd";
const Context = createContext();

const GlobalStyles = createGlobalStyle`
    html {
    --primary: 24,144,255;
    --color-primary: rgb(24,144,255); 
    ${process.env.REACT_APP_BUILD === 'arc'
    && css`
        --primary: 20,112,166;
        --color-primary: rgb(20,112,166); 
    `}
  }
`;

function AuthProvider({ children }) {

    const [authenticated, setAuthenticated] = useState(false);
    const [userData, setUserData] = useState({})
    const [loading, setLoading] = useState(true);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [loadingValidate, setLoadingValidate] = useState(false);
    const [errorLogin, setErrorLogin] = useState(false)
    const [errorCode, setErrorCode] = useState(false)
    const [step, setStep] = useState(1);
    const [code, setCode] = useState();
    const [token, setToken] = useState();

    useEffect(() => {

        const token = localStorage.getItem('token');
        const userToken = localStorage.getItem('user');

        if (token) {
            api.defaults.headers.common['Authorization'] = JSON.parse(token)
            setAuthenticated(true)
            setUserData(JSON.parse(atob(JSON.parse(userToken))))
        }

        setLoading(false);

    }, []);

    async function handleSendCode(email, password) {
        setErrorLogin(false)

        const data = await api.post('/users/authenticate', {
            email,
            password,
            build: process.env.REACT_APP_BUILD,
            app_name: process.env.REACT_APP_NAME
        }).then(response => {
            const userToken = btoa(JSON.stringify(response.data.user))

            localStorage.setItem('token', JSON.stringify(response.data.token))
            localStorage.setItem('user', JSON.stringify(userToken))

            setToken(response.data.token)

            // api.defaults.headers.common['Authorization'] = response.data.token
            // setAuthenticated(true)
            // setUserData(response.data.user)
            // setLoadingSubmit(false)
            // history.push('/customers')

            setStep(2);
            setLoadingSubmit(false);

        }).catch(error => {
            console.log(error.response.data)
            setErrorLogin(true)
            setLoadingSubmit(false)
        })
    }

    async function handleLogin(email, password) {
        setErrorCode(false)

        const data = await api.post('/users/authenticate/validateCode', {
            code,
            token
        }).then(response => {
            const userToken = btoa(JSON.stringify(response.data.user))

            localStorage.setItem('token', JSON.stringify(response.data.token))
            localStorage.setItem('user', JSON.stringify(userToken))

            api.defaults.headers.common['Authorization'] = response.data.token
            setAuthenticated(true)
            setUserData(response.data.user)
            setLoadingValidate(false)
            history.push('/customers')
            setStep(1);
            setCode()

        }).catch(error => {
            console.log(error.response.data)
            setErrorCode(true)
            setLoadingValidate(false)
        })
    }

    function handleLogout() {
        localStorage.removeItem('token');
        api.defaults.headers.common['Authorization'] = undefined;
        setAuthenticated(false)
        history.push('/')
    }

    return (
        <Context.Provider value={{
            authenticated,
            code,
            setCode,
            step,
            setStep,
            handleSendCode,
            handleLogin,
            handleLogout,
            errorLogin,
            errorCode,
            userData,
            loading,
            loadingSubmit,
            setLoadingSubmit,
            loadingValidate,
            setLoadingValidate,
            token
        }}>
            <GlobalStyles />
            {children}
        </Context.Provider>
    )
}

export { Context, AuthProvider };
