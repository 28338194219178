import React, { useContext, useState, useEffect } from 'react';
import { Form, Input, Button, Select, Pagination, Spin } from 'antd';

import { useLocation } from 'react-router';
import queryString from 'query-string';

import statusJson from '../../helpers/status.json';

import api from '../../api';
import SideBar from '../../components/common/sidebar';
import ContentHeader from '../../components/common/contentHeader';

import './customers.css';
import { BiReceipt } from "react-icons/bi";
import { Context } from '../../context/AuthContext';

import Filter from './filter';
import EditCustomer from './editCustomer';
import AddCustomer from './addCustomer';
import List from './list';

function Customers() {
    const { userData } = useContext(Context);

    const [users, setUsers] = useState([])
    const [statusList, setStatusList] = useState([])
    const [customers, setCustomers] = useState([])
    const [customer, setCustomer] = useState({})
    const [loadCustomers, setLoadCustomers] = useState(true)
    const [totalCustomers, setTotalCustomers] = useState(0);
    const [modalCustomer, setModalCustomer] = useState(false);
    const [statusTotal, setStatusTotal] = useState(0);
    const [page, setPage] = useState(1)

    // Filters
    const [filters, setFilters] = useState({})

    //
    const location = useLocation();
    const query = queryString.parse(location.search);
    const customerSearchId = query.id

    useEffect(() => {
        (async () => {
            const { data } = await api.get('/users/call-center');

            setUsers(data)

            // setUsers(data.filter(e => {
            //     if (userData.users?.length) {
            //         if (userData.users.includes(e._id)) {
            //             return e;
            //         }
            //     } else {
            //         return e;
            //     }
            // }));
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const { data } = await api.get('/customers');
            setCustomers(data.customers);
            setTotalCustomers(data.total);
            setLoadCustomers(false)
            // Se tiver id para buscar
            if (customerSearchId) {
                const { data } = await api.get(`/customers/${customerSearchId}`);
                setCustomer(data)
                setModalCustomer(true)
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const { data } = await api.get('/customers/totals/status');
            setStatusTotal(data);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            let _statusList = await statusJson.filter(status => {
                if (userData.roles.includes('call-center') && (status.id == 'agreed' || status.id == 'done')) {
                    return false
                } else {
                    return status
                }
            })
            setStatusList(_statusList)
        })();
    }, []);

    function itemRender(current, type, originalElement) {
        if (type === 'prev') {
            return <a>Anterior</a>;
        }
        if (type === 'next') {
            return <a>Próxima</a>;
        }
        return originalElement;
    }

    function selectCustomer(id) {
        let customer = customers.filter((item) => {
            return item._id == id
        })[0]
        setCustomer(customer)
        setModalCustomer(true)
    }

    async function changePage(page) {
        setLoadCustomers(true)

        let url = `/customers?page=${page}`

        if (filters.wallet)
            url = url.concat(`&wallet=${filters.wallet}`)

        if (filters.status)
            url = url.concat(`&status=${filters.status}`)

        if (filters.priority)
            url = url.concat(`&priority=${filters.priority}`)

        if (filters.responsible)
            url = url.concat(`&responsible=${filters.responsible}`)

        if (filters.search)
            url = url.concat(`&search=${filters.search}`)

        const { data } = await api.get(url);
        setCustomers(data.customers);
        setPage(page)
        setLoadCustomers(false)
    }

    return (
        <div className="page-content">
            <SideBar page="customers" />
            <div className="content">
                <ContentHeader title="Clientes" icon={<BiReceipt size="2em" />} />


                <div className="customers-container">

                    <Filter
                        setLoadCustomers={setLoadCustomers}
                        setTotalCustomers={setTotalCustomers}
                        statusTotal={statusTotal}
                        setStatusTotal={setStatusTotal}
                        statusList={statusList}
                        customers={customers}
                        setCustomers={setCustomers}
                        filters={filters}
                        setFilters={setFilters}
                        users={users}
                    />

                    {/* { customers.length > 0 &&
                    <div className="customers-totals">
                        <div className="item first">
                            <span className="status-name">Total de clientes:</span>
                            <span className="status-number">{statusTotal.total}</span>
                        </div>
                        <div className="status-list">
                            {statusTotal.created > 0 &&
                                <div className="item">
                                    <span className="status-icon" style={{background: '#1890ff'}}></span>
                                    <span className="status-name">Prospecção:</span>                    
                                    <span className="status-number">{statusTotal.created}</span>
                                </div>
                            }
                            {statusTotal.interest > 0 &&
                                <div className="item">
                                    <span className="status-icon"  style={{background: '#13c2c2'}}></span>
                                    <span className="status-name">Interesse:</span>                    
                                    <span className="status-number">{statusTotal.interest}</span>
                                </div>
                            }
                            {statusTotal.notInterested > 0 &&
                                <div className="item">
                                    <span className="status-icon"  style={{background: '#faad14'}}></span>
                                    <span className="status-name">Sem interesse:</span>
                                    <span className="status-number">{statusTotal.notInterested}</span>
                                </div>
                            }                       
                            {statusTotal.fail > 0 &&        
                                <div className="item">
                                    <span className="status-icon"  style={{background: '#f5222d'}}></span>
                                    <span className="status-name">Falha no Contato:</span>                    
                                    <span className="status-number">{statusTotal.fail}</span>
                                </div>
                            }
                            {statusTotal.returns > 0 &&
                                <div className="item">
                                    <span className="status-icon"  style={{background: '#eb2f96'}}></span>
                                    <span className="status-name">Retornos:</span>                    
                                    <span className="status-number">{statusTotal.returns}</span>
                                </div>
                            }
                            {statusTotal.visit > 0 &&
                                <div className="item">
                                    <span className="status-icon"  style={{background: '#eb2f96'}}></span>
                                    <span className="status-name">Visita:</span>                    
                                    <span className="status-number">{statusTotal.visit}</span>
                                </div>
                            }
                            {statusTotal.disease > 0 &&
                                <div className="item">
                                    <span className="status-icon"  style={{background: '#722ed1'}}></span>
                                    <span className="status-name">Doença pré existente:</span>                    
                                    <span className="status-number">{statusTotal.disease}</span>
                                </div>
                            }
                        </div>
                    </div>
                } */}


                    {loadCustomers
                        ? <Spin tip="Carregando clientes..."><div className="loading-customers"></div></Spin>
                        : <div className="customers-list">
                            {userData.roles.includes('admin') && (<AddCustomer customer={customer} customers={customers} setCustomers={setCustomers} setStatusTotal={setStatusTotal} />)}
                            <List customers={customers} setCustomers={setCustomers} selectCustomer={selectCustomer} />
                        </div>
                    }

                    {customers.length > 0 &&
                        <div className="customers-pagination">
                            <Pagination
                                onChange={(e) => changePage(e)}
                                total={totalCustomers}
                                itemRender={itemRender}
                                current={page} s
                                howSizeChanger={false}
                            />
                        </div>
                    }
                </div>

                {customer &&
                    <EditCustomer
                        statusList={statusList}
                        customer={customer}
                        setCustomer={setCustomer}
                        setCustomers={setCustomers}
                        setStatusTotal={setStatusTotal}
                        modalCustomer={modalCustomer}
                        setModalCustomer={setModalCustomer}
                        page={page}
                        changePage={changePage}
                        filters={filters}
                        userData={userData}
                    />
                }
            </div>
        </div>
    )

}


export default Customers;
