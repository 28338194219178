import React from 'react';

function statusMask(props) {

    let { status } = props;

    switch (status) {
        case 'just-rings':
            return (<span className={status}>Só chama</span>);
        case 'first-contact':
            return (<span className={status}>1° Contato</span>);
        case 'created':
            return (<span className={status}>Prospecção</span>);
        case 'analyze':
            return (<span className={status}>Em análise</span>);
        case 'interest':
            return (<span className={status}>Interesse</span>);
        case 'not-interested':
            return (<span className={status}>Sem Interesse</span>);
        case 'negotiation':
            return (<span className={status}>Em negociação</span>);
        case 'fail':
            return (<span className={status}>Falha no contato</span>);
        case 'return':
            return (<span className={status}>Retorno</span>);
        case 'visit':
            return (<span className={status}>Visita</span>);
        case 'disease':
            return (<span className={status}>Doença pré existente</span>);
        case 'agreed':
            return (<span className={status}>Fechado</span>);
        case 'done':
            return (<span className={status}>Concluído</span>);
        case 'analysis':
            return (<span className={status}>Em análise</span>);
        case 'waiting':
            return (<span className={status}>Aguardando</span>);
        case 'changing':
            return (<span className={status}>Trocando</span>);
        default:
            return (<span>---</span>);
    }

}

export default statusMask;