import React, { useState, useContext } from 'react';
import { Form, Input, Button, Alert } from 'antd';
import { useHistory } from 'react-router'
import VerificationInput from "react-verification-input";
import { Context } from '../../context/AuthContext';

import './login.css';
import logo from './images/planos_vita.png';
import logoArc from './images/arc.png';
import background from './images/background-login.jpg';

function Login() {
  const { push } = useHistory()
  const { authenticated, step, setStep, code, setCode, handleSendCode, handleLogin, errorLogin, errorCode, loadingSubmit, setLoadingSubmit, loadingValidate, setLoadingValidate, token } = useContext(Context);

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)

  const onFinish = values => {
    setLoadingSubmit(true)
    handleSendCode(email, password)
  };

  const validateCodde = () => {
    if (code && code.length === 6) {
      setLoadingValidate(true)
      handleLogin(code, token)
    }
  };

  return (
    <div className="login-container" style={{ backgroundImage: process.env.REACT_APP_BUILD === "planos_vita" ? `url(${background})` : 'none' }}>

      <div className="login-box">
        <div className="login-box-inner">

          {process.env.REACT_APP_BUILD === "planos_vita"
            ? <img src={logo} alt="Logo" width="150" className="login-box-logo" />
            : <img src={logoArc} alt="Logo" width="150" className="login-box-logo" />
          }

          <h2>Fazer Login</h2>
          <h3>Faça seu login para acessar o {process.env.REACT_APP_NAME}</h3>

          {errorLogin &&
            <Alert
              style={{ marginBottom: '20px' }}
              description="Usuário ou senha inválidos."
              type="error"
            />
          }

          {errorCode &&
            <Alert
              style={{ marginBottom: '20px' }}
              description="Código inválido."
              type="error"
            />
          }

          {step === 1 &&
            <Form layout="vertical" onFinish={onFinish}>
              <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Digite seu e-mail.' }]} >
                <Input
                  name="email"
                  type="email"
                  placeholder="seuemail@email.com"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  className='default'
                />
              </Form.Item>

              <Form.Item label="Senha" name="password" rules={[{ required: true, message: 'Digite sua senha.' }]} >
                <Input
                  name="password"
                  type="password"
                  placeholder="******"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  className='default'
                />
              </Form.Item>
              <div className="login-box-buttons">
                <Button type="primary" size="large" style={{ width: '200px' }} htmlType="submit" loading={loadingSubmit}> Fazer Login </Button>
                <Button type="link" onClick={() => push('/forgot-password')}>Esqueci minha senha</Button>
              </div>
            </Form>
          }

          {step === 2 &&
            <div>
              <p>Digite abaixo o códdigo que enviamos para <strong>{email}</strong></p>
              <VerificationInput
                onChange={(e) => { setCode(e) }}
                classNames={{
                  container: "code",
                  character: "character",
                  characterInactive: "character--inactive",
                  characterSelected: "character--selected",
                  characterFilled: "character--filled",
                }}
              />

              <div className="login-box-buttons">
                <Button type="primary" size="large" style={{ width: '200px' }} loading={loadingValidate} onClick={() => validateCodde()}> Validar código </Button>
                <Button type="link" onClick={() => setStep(1)}>Reenviar códdigo</Button>
              </div>
            </div>
          }

        </div>

      </div>

    </div>
  );

}

export default Login;
