import React, { useEffect } from "react";
import { Router } from 'react-router-dom';

import Routes from './routes';
import history from './history';

import { AuthProvider } from './context/AuthContext';
import { Helmet } from "react-helmet";


function App() {
    const siteTitle = process.env.REACT_APP_NAME || "Título Padrão";
    const faviconPath = process.env.REACT_APP_BUILD + "_favicon.png";

    useEffect(() => {
        const favicon = document.querySelector("link[rel='icon']");
        if (favicon) {
            favicon.href = faviconPath;
        }
    }, [faviconPath]);

    return (
        <AuthProvider>
            <Helmet>
                <title>{siteTitle}</title>
            </Helmet>
            <Router history={history}>
                <Routes />
            </Router>
        </AuthProvider>
    )
}

export default App;