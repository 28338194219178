import React from 'react';
import { BiReceipt, BiWalletAlt, BiCog } from "react-icons/bi";
import { PiTarget } from "react-icons/pi";

import history from '../../../history';
import './sidebar.css';

function SideBar(props) {

    function go(page) {
        history.push(page)
    }

    return (
        <div className="sidebar">
            <div className="sidebar-header">
                <img src={process.env.PUBLIC_URL + `/${process.env.REACT_APP_BUILD}.png`} />
            </div>
            <div className="menu">
                <ul>
                    <li className={props.page === 'customers' ? 'active' : ''} onClick={() => go("/customers")} >
                        <BiReceipt size="15px" />
                        <span>Clientes</span>
                    </li>
                    <li className={props.page === 'goals' ? 'active' : ''} onClick={() => go("/goals")} >
                        <PiTarget size="19px" />
                        <span>Metas</span>
                    </li>
                    <li className={props.page === 'wallets' ? 'active' : ''} onClick={() => go("/wallets")} >
                        <BiWalletAlt size="15px" />
                        <span>Carteiras</span>
                    </li>
                    <li className={props.page === 'configs' ? 'active' : ''} onClick={() => go("/configs")}  >
                        <BiCog size="15px" />
                        <span>Configurações</span>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default SideBar;