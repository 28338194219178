import React, { useState, useContext } from 'react';
import { Form, Input, Button, Alert, message } from 'antd';
import { Link } from "react-router-dom";
import api from '../../../api';

import '../login.css';
import logo from '../images/planos_vita.png';
import logoArc from '../images/arc.png';
import background from '../images/background-login.jpg';

function ForgotPassword() {

  const [error, setErrors] = useState(false)
  const [loading, setLoading] = useState(false)
  const [emailSent, setEmailSent] = useState(false)

  const onFinish = async values => {
    setLoading(true)
    const data = await api.post('/users/forgot-password', { email: values.email }).then(async response => {
      setLoading(false)
      message.success(`Email enviado com sucesso para ${values.email}`);
    }).catch(error => {
      console.log(error)
      setLoading(false)
      message.error('Erro ao enviar email.');
    })
  };

  return (
    <div className="login-container" style={{ backgroundImage: `url(${background})` }}>

      <div className="login-box">
        <div className="login-box-inner">

          {process.env.REACT_APP_BASE_BUILD === "default"
            ? <img src={logo} alt="Logo" width="150" className="login-box-logo" />
            : <img src={logoArc} alt="Logo" width="150" className="login-box-logo" />
          }

          <h2>Esqueci minha senha</h2>
          <h3>Informe seu e-mail do sistema +Vita</h3>

          <Form layout="vertical" onFinish={onFinish}>
            <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Digite seu e-mail.' }]} >
              <Input
                name="email"
                type="email"
                placeholder="Digite seu email"
              />
            </Form.Item>

            <div className="login-box-buttons">
              <Button type="primary" size="large" style={{ width: '200px' }} htmlType="submit" loading={loading}> Enviar </Button>
              <Link to="/"><Button type="link">Voltar para login</Button></Link>
            </div>

          </Form>

        </div>
      </div>

    </div>
  );

}

export default ForgotPassword;
